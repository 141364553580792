import { LoadingStateEnum } from '@dmc-ng/data-access';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { isArray } from 'lodash';

import { accountsActions } from './accounts.actions';
import { AccountModel } from '../models/account.model';

export const ACCOUNTS_FEATURE_KEY = 'accounts';

export const accountsAdapter: EntityAdapter<AccountModel> =
  createEntityAdapter<AccountModel>();
export const paginatedAccountAdapter: EntityAdapter<AccountModel> =
  createEntityAdapter<AccountModel>({
    selectId: (account) => account.id,
  });

export interface PaginatedAccountsState extends EntityState<AccountModel> {
  loading: LoadingStateEnum;
  total?: number;
  error?: unknown;
}

export interface AccountsState extends EntityState<AccountModel> {
  error?: unknown;
  loading: LoadingStateEnum;
  paginatedAccounts: PaginatedAccountsState;
}

export const initialAccountsState: AccountsState =
  accountsAdapter.getInitialState({
    loading: LoadingStateEnum.Ready,
    paginatedAccounts: paginatedAccountAdapter.getInitialState({
      loading: LoadingStateEnum.Ready,
    }),
  });

const reducer = createReducer(
  initialAccountsState,
  on(accountsActions.getPaginatedAccounts, (state) => ({
    ...state,
    paginatedAccounts: {
      ...state.paginatedAccounts,
      loading: LoadingStateEnum.Loading,
    },
  })),
  on(
    accountsActions.accountsPaginatedRetrievedSuccessfully,
    (state, { accounts, total }) => ({
      ...accountsAdapter.upsertMany(isArray(accounts) ? accounts : [], {
        ...state,
        loading: LoadingStateEnum.Done,
      }),
      paginatedAccounts: {
        ...paginatedAccountAdapter.setAll(
          isArray(accounts) ? accounts : [],
          state.paginatedAccounts,
        ),
        total,
        loading: LoadingStateEnum.Done,
      },
    }),
  ),
  on(
    accountsActions.accountsPaginatedFailedToBeRetrieved,
    (state, { error }) => ({
      ...state,
      paginatedAccounts: {
        ...state.paginatedAccounts,
        loading: LoadingStateEnum.Done,
        error,
      },
    }),
  ),
  on(
    accountsActions.getAccounts,
    accountsActions.getAccount,
    accountsActions.createAccount,
    accountsActions.updateAccount,
    accountsActions.deleteAccount,
    (state) => ({ ...state, loading: LoadingStateEnum.Loading }),
  ),
  on(accountsActions.accountsRetrievedSuccessfully, (state, { accounts }) =>
    accountsAdapter.upsertMany(isArray(accounts) ? accounts : [], {
      ...state,
      loading: LoadingStateEnum.Done,
    }),
  ),
  on(
    accountsActions.accountUpdatedSuccessfully,
    accountsActions.accountCreatedSuccessfully,
    (state, { account }) =>
      accountsAdapter.upsertOne(account, {
        ...state,
        loading: LoadingStateEnum.Done,
      }),
  ),
  on(accountsActions.deleteAccount, (state, { accountId }) =>
    accountsAdapter.removeOne(accountId, {
      ...state,
      loading: LoadingStateEnum.Done,
    }),
  ),
  on(
    accountsActions.accountsFailedToBeRetrieved,
    accountsActions.accountFailedToBeRetrieved,
    accountsActions.accountFailedToBeCreated,
    accountsActions.accountFailedToBeUpdated,
    accountsActions.accountFailedToBeDeleted,
    (state, { error }) => ({ ...state, error, loading: LoadingStateEnum.Done }),
  ),
  on(accountsActions.accountRetrievedSuccessfully, (state, { account }) =>
    accountsAdapter.upsertOne(account, {
      ...state,
      loading: LoadingStateEnum.Done,
    }),
  ),
  on(accountsActions.getAllAccountsForAllOrganizations, (state) => ({
    ...state,
    loadingAllAccounts: LoadingStateEnum.Loading,
  })),
  on(
    accountsActions.allAccountsForAllOrganizationsRetrievedSuccessfully,
    (state, { accounts }) =>
      accountsAdapter.upsertMany(accounts ?? [], {
        ...state,
        loadingAllAccounts: LoadingStateEnum.Done,
      }),
  ),
);

export function accountsReducer(
  state: AccountsState | undefined,
  action: Action,
): AccountsState {
  return reducer(state, action);
}
