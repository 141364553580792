import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
  APIResponse,
  DeepPartial,
  PaginatedResponse,
  UploadLogoModel,
} from '@dmc-ng/data-access';
import { API_BASE_URL } from '@dmc-ng/data-access/injection-token';
import { SortModel, SortUtils } from '@dmc-ng/utils';
import { Observable } from 'rxjs';

import { AccountModel } from './models/account.model';

@Injectable()
export class AccountService {
  private readonly apiBaseUrl = inject(API_BASE_URL);

  constructor(private readonly httpClient: HttpClient) {}

  getAccounts(organizationId: string): Observable<APIResponse<AccountModel[]>> {
    return this.httpClient.get<APIResponse<AccountModel[]>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/all`,
    );
  }

  getPaginatedAccounts(
    organizationId: string,
    offset?: number,
    limit?: number,
    sort?: SortModel,
  ): Observable<APIResponse<PaginatedResponse<AccountModel>>> {
    let params = new HttpParams();

    if (offset) {
      params = params.append('offset', offset);
    }
    if (limit) {
      params = params.append('limit', limit);
    }
    if (sort) {
      params = params.append('sort', SortUtils.createQueryParams(sort));
    }
    return this.httpClient.get<APIResponse<PaginatedResponse<AccountModel>>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/`,
      { params },
    );
  }

  getAccount(
    organizationId: string,
    accountId: string,
  ): Observable<APIResponse<AccountModel>> {
    return this.httpClient.get<APIResponse<AccountModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}`,
    );
  }

  postAccount(
    organizationId: string,
    account: DeepPartial<AccountModel>,
  ): Observable<APIResponse<AccountModel>> {
    return this.httpClient.post<APIResponse<AccountModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/`,
      account,
    );
  }

  putAccount(
    organizationId: string,
    accountId: string,
    accountToEdit: DeepPartial<AccountModel>,
  ): Observable<APIResponse<AccountModel>> {
    return this.httpClient.put<APIResponse<AccountModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}`,
      accountToEdit,
    );
  }

  deleteAccount(organizationId: string, accountId: string): Observable<void> {
    return this.httpClient.delete<void>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}`,
    );
  }

  postAccountLogo(
    organizationId: string,
    accountId: string,
    uploadLogo: UploadLogoModel,
  ): Observable<APIResponse<AccountModel>> {
    return this.httpClient.post<APIResponse<AccountModel>>(
      `${this.apiBaseUrl}/organizations/${organizationId}/accounts/${accountId}/logo`,
      uploadLogo,
    );
  }
}
