import { Injectable } from '@angular/core';
import {
  APIResponse,
  PaginatedResponse,
  UploadLogoModel,
} from '@dmc-ng/data-access';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, of, switchMap } from 'rxjs';

import { accountsActions } from './accounts.actions';
import { AccountService } from '../accounts.service';
import { AccountModel } from '../models/account.model';

@Injectable()
export class AccountsEffects {
  accounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountsActions.getAccounts),
      switchMap(({ organizationId }) =>
        this.accountsService.getAccounts(organizationId).pipe(
          map((accountsResult: APIResponse<AccountModel[]>) =>
            accountsActions.accountsRetrievedSuccessfully({
              accounts: accountsResult.data as AccountModel[],
            }),
          ),
          catchError((error) =>
            of(accountsActions.accountsFailedToBeRetrieved({ error })),
          ),
        ),
      ),
    ),
  );

  paginatedAccounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountsActions.getPaginatedAccounts),
      switchMap(({ organizationId, limit, offset, sort }) =>
        this.accountsService
          .getPaginatedAccounts(organizationId, offset, limit, sort)
          .pipe(
            map(({ data }) =>
              accountsActions.accountsPaginatedRetrievedSuccessfully({
                accounts: (data as PaginatedResponse<AccountModel>).items,
                total: (data as PaginatedResponse<AccountModel>).total,
              }),
            ),
            catchError((error) =>
              of(
                accountsActions.accountsPaginatedFailedToBeRetrieved({ error }),
              ),
            ),
          ),
      ),
    ),
  );

  accountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountsActions.getAccount),
      switchMap(({ organizationId, accountId }) =>
        this.accountsService.getAccount(organizationId, accountId).pipe(
          map((accountResult: APIResponse<AccountModel>) =>
            accountsActions.accountRetrievedSuccessfully({
              account: accountResult.data as AccountModel,
            }),
          ),
          catchError((error) =>
            of(accountsActions.accountFailedToBeRetrieved({ error })),
          ),
        ),
      ),
    ),
  );

  createAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountsActions.createAccount),
      switchMap(({ account, organizationId, logo }) =>
        this.accountsService.postAccount(organizationId, account).pipe(
          map((accountResult: APIResponse<AccountModel>) =>
            accountsActions.accountCreatedSuccessfully({
              account: accountResult.data as AccountModel,
              logo,
            }),
          ),
          catchError((error) =>
            of(accountsActions.accountFailedToBeCreated({ error })),
          ),
        ),
      ),
    ),
  );

  updateAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountsActions.updateAccount),
      switchMap(({ account, organizationId, accountId, logo }) =>
        this.accountsService
          .putAccount(organizationId, accountId, account)
          .pipe(
            map((accountResult: APIResponse<AccountModel>) =>
              accountsActions.accountUpdatedSuccessfully({
                account: accountResult.data as AccountModel,
                logo,
              }),
            ),
            catchError((error) =>
              of(accountsActions.accountFailedToBeUpdated({ error })),
            ),
          ),
      ),
    ),
  );

  deleteAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountsActions.deleteAccount),
      switchMap(({ organizationId, accountId }) =>
        this.accountsService.deleteAccount(organizationId, accountId).pipe(
          map(() => accountsActions.accountDeleteSuccessfully({ accountId })),
          catchError((error) =>
            of(accountsActions.accountFailedToBeDeleted({ error })),
          ),
        ),
      ),
    ),
  );

  saveLogoToAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountsActions.addLogoForAccount),
      switchMap(({ accountId, uploadLogo, organizationId }) =>
        this.accountsService
          .postAccountLogo(organizationId, accountId, uploadLogo)
          .pipe(
            map((updatedAccount: APIResponse<AccountModel>) =>
              accountsActions.accountLogoAddedSuccessfully({
                account: updatedAccount.data as AccountModel,
              }),
            ),
            catchError((error) =>
              of(accountsActions.accountLogoFailedToBeAdded({ error })),
            ),
          ),
      ),
    ),
  );

  addLogoForAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        accountsActions.accountCreatedSuccessfully,
        accountsActions.accountUpdatedSuccessfully,
      ),
      filter(({ logo }) => !!logo),
      map(({ account, logo }) =>
        accountsActions.addLogoForAccount({
          organizationId: account.organizationId,
          accountId: account.id,
          uploadLogo: logo as UploadLogoModel,
        }),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private accountsService: AccountService,
  ) {}
}
