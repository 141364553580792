import { createFeatureSelector, createSelector } from '@ngrx/store';

import {
  AccountsState,
  accountsAdapter,
  paginatedAccountAdapter,
} from './accounts.reducers';
import { AccountModel } from '../models/account.model';

export const selectAccountsState =
  createFeatureSelector<AccountsState>('accounts');
const {
  selectEntities: paginatedAccountsEntities,
  selectAll: paginatedAccounts,
} = paginatedAccountAdapter.getSelectors();

export const {
  selectIds: selectAccountIds,
  selectEntities: selectAccountEntities,
  selectAll: selectAllAccounts,
  selectTotal: selectTotalAccounts,
} = accountsAdapter.getSelectors(selectAccountsState);

export const selectAccountsLoading = createSelector(
  selectAccountsState,
  (state: AccountsState) => state.loading,
);

export const selectAccountsByOrganizationId = (organizationId: string) =>
  createSelector(selectAllAccounts, (accounts: AccountModel[]) =>
    accounts.filter((account) => account.organizationId === organizationId),
  );

export const selectPaginatedAccountsState = createSelector(
  selectAccountsState,
  (state) => state.paginatedAccounts,
);
export const selectPaginatedAccountsEntities = createSelector(
  selectPaginatedAccountsState,
  paginatedAccountsEntities,
);
export const selectPaginatedAccounts = createSelector(
  selectPaginatedAccountsState,
  paginatedAccounts,
);
export const selectPaginatedAccountsError = createSelector(
  selectPaginatedAccountsState,
  (state) => state.error,
);
export const selectPaginatedAccountsLoading = createSelector(
  selectPaginatedAccountsState,
  (state) => state.loading,
);
export const selectPaginatedAccountsTotal = createSelector(
  selectPaginatedAccountsState,
  (state) => state.total,
);
