import { DeepPartial, UploadLogoModel } from '@dmc-ng/data-access';
import { SortModel } from '@dmc-ng/utils';
import { createActionGroup, props } from '@ngrx/store';

import { AccountModel } from '../models/account.model';

export const accountsActions = createActionGroup({
  source: 'Accounts',
  events: {
    'Get Accounts': props<{ organizationId: string }>(),
    'Accounts retrieved successfully': props<{ accounts: AccountModel[] }>(),
    'Accounts failed to be retrieved': props<{ error: unknown }>(),

    'Get paginated Accounts': props<{
      organizationId: string;
      limit?: number;
      offset?: number;
      sort?: SortModel;
    }>(),
    'Accounts paginated retrieved successfully': props<{
      accounts: AccountModel[];
      total: number;
    }>(),
    'Accounts paginated failed to be retrieved': props<{ error: unknown }>(),

    'Get Account': props<{ organizationId: string; accountId: string }>(),
    'Account retrieved successfully': props<{ account: AccountModel }>(),
    'Account failed to be retrieved': props<{ error: unknown }>(),

    'Create Account': props<{
      account: DeepPartial<AccountModel>;
      organizationId: string;
      logo?: UploadLogoModel;
    }>(),
    'Account created successfully': props<{
      account: AccountModel;
      logo?: UploadLogoModel;
    }>(),
    'Account failed to be created': props<{ error: unknown }>(),

    'Update Account': props<{
      organizationId: string;
      accountId: string;
      account: DeepPartial<AccountModel>;
      logo?: UploadLogoModel;
    }>(),
    'Account updated successfully': props<{
      account: AccountModel;
      logo?: UploadLogoModel;
    }>(),
    'Account failed to be updated': props<{ error: unknown }>(),

    'Delete Account': props<{ organizationId: string; accountId: string }>(),
    'Account delete successfully': props<{ accountId: string }>(),
    'Account failed to be deleted': props<{ error: unknown }>(),

    'Get all Accounts for all Organizations': props<{
      organizationIds: string[];
    }>(),
    'All Accounts for all Organizations retrieved successfully': props<{
      accounts: AccountModel[];
    }>(),
    'All Accounts for all Organizations failed to be retrieved': props<{
      error: unknown;
    }>(),

    'Add logo for Account': props<{
      accountId: string;
      organizationId: string;
      uploadLogo: UploadLogoModel;
    }>(),
    'Account logo added successfully': props<{ account: AccountModel }>(),
    'Account logo failed to be added': props<{ error: unknown }>(),
  },
});
